import 'styles/index'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import $ from 'jquery'
import Masonry from 'masonry-layout'
import imagesLoaded from "imagesloaded"

function hasClass( target, className ) {
    return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
}

function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}

function vimeo_parser(url){
    var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    var match = url.match(regExp);
    return (match&&match[5])? match[5] : false;
}


window.addEventListener('load', function(event) {
    
/* -------------------------------------------------------------------------- */
/*                                     NAV                                    */
/* -------------------------------------------------------------------------- */

    let body = document.querySelector("body")
    let nav_toggler = document.getElementById("nav-toggler")
    let nav_burger = document.querySelector(".burger.d-block")
    let nav_close = document.querySelector(".close-nav-wrap")
    let nav_collapse = document.getElementById("nav-collapse")

    var search_on = false
    const $search_group = document.getElementById('search-group')
    const $search_input = document.querySelector('#search-group input')
    const $search_button = document.querySelector('#search-group button')

    const $search_toggle_mobile = document.getElementById('search-toggle-mobile')
    const $search_group_mobile = document.getElementById('search-group-mobile')
    const $nav_collapse_content = document.getElementById('nav-collapse-main-content')

    nav_toggler.addEventListener("click", () => {

        if( hasClass(nav_collapse, 'd-none') ){
            body.classList.add('overflow-hidden')
            nav_collapse.classList.remove('d-none')
            nav_burger.classList.add('d-none')
            nav_burger.classList.remove('d-block')
            nav_close.classList.remove('d-none')
        }else{
            body.classList.remove('overflow-hidden')
            nav_collapse.classList.add('d-none')
            nav_burger.classList.remove('d-none')
            nav_burger.classList.add('d-block')
            nav_close.classList.add('d-none')
            $nav_collapse_content.classList.add('d-flex')
            $nav_collapse_content.classList.remove('d-none')
            $nav_collapse_content.classList.remove('d-none')
            $search_group_mobile.classList.add('d-none')
            $search_group_mobile.classList.remove('d-flex')
        }

    })

/* -------------------------------------------------------------------------- */
/*                                   SEARCH                                   */
/* -------------------------------------------------------------------------- */

    const $search_more = $('#search-more')
    
    $search_more.on('click', e => {
            e.preventDefault()
            $.ajax({
                url : e.currentTarget.href
            }).then( response => {
                let $items = $(response).find('.grid-item')
                let $more = $(response).find('#search-more')
                $items.addClass('opacity-0')
                $('section.grid').append($items)
                imagesLoaded( $items, () => {
                    msnry.appended($items)
                    $items.removeClass('opacity-0')
                    if( $more.length > 0 ){
                        $search_more.attr('href', $more.attr('href'))
                    }else{
                        $search_more.remove()
                    }
                })
                
            })
    })
    

    $search_group.addEventListener( 'submit', e => {
        // return false

        if( $search_input.value === "" ){
            e.preventDefault()
        }else{
            return
        }
    })

    $search_button.addEventListener('click', function (e) {
        // return false
        
        e.preventDefault()
        
        if( !search_on ){
            $search_input.classList.remove('w-0')
            $search_input.focus()
            search_on = true
        }else{
            if( $search_input.value === "" ){
                $search_input.classList.add('w-0')
                search_on = false
            }else{
                $search_group.submit()
            }
        }

    })

    $search_toggle_mobile.addEventListener('click', e => {

        $nav_collapse_content.classList.remove('d-flex')
        $nav_collapse_content.classList.add('d-none')
        
        $search_group_mobile.classList.remove('d-none')
        $search_group_mobile.classList.add('d-flex')

    })

/* -------------------------------------------------------------------------- */
/*                             SCROLL ARCHIVED                                */
/* -------------------------------------------------------------------------- */
    
    const archived_images_wrap = document.querySelector(".archived-images-wrap")
    const archived_list_wrap = document.querySelector(".archived-list-wrap")
    const archived_films = document.querySelectorAll('.archived-film')
    const archived_imgs = document.querySelectorAll('.archived-images-wrap img')

    if( archived_images_wrap ){

        window.addEventListener('scroll', () => {

            let scrollTop = window.pageYOffset + 200
            let limit_top = archived_list_wrap.offsetTop
            let limit_bottom = limit_top + archived_list_wrap.clientHeight - archived_images_wrap.clientHeight
            
            let tr = scrollTop - limit_top
            tr = tr < 0 ? 0  : (scrollTop > limit_bottom ? archived_list_wrap.clientHeight - archived_images_wrap.clientHeight : tr )
            
            archived_images_wrap.style.transform = "translateY(" + tr + "px)"
            
            // console.log(window.pageYOffset)
        })

    }

/* -------------------------------------------------------------------------- */
/*                               HOVER ARCHIVED                               */
/* -------------------------------------------------------------------------- */

    
    if( archived_list_wrap != null ){

        archived_list_wrap.addEventListener('mouseleave', () => {
            archived_imgs.forEach( el => {
                if( !el.classList.contains('d-none') ){
                    el.classList.add('d-none')
                }
            })
        })

    }
    
    if( archived_films != null ){

        archived_films.forEach( el => {
            el.addEventListener('mouseover', () => {
                
                archived_imgs.forEach( el => {
                    if( !el.classList.contains('d-none') ){
                        el.classList.add('d-none')
                    }
                })
                
                let data_film = el.dataset.film
                let img = document.querySelector('.archived-images-wrap img[data-film="' + data_film + '"]')
                img.classList.remove('d-none')
            })
        })
    }    

    
/* -------------------------------------------------------------------------- */
/*                                   MASONRY                                  */
/* -------------------------------------------------------------------------- */

    if( document.querySelector('.grid') ){

        var msnry = new Masonry( '.grid', {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            transitionDuration: 100
        });
    }



/* -------------------------------------------------------------------------- */
/*                                MODAL PLAYER                                */
/* -------------------------------------------------------------------------- */

    const modal_video = document.querySelector('.modal-video')

    if( modal_video ){

        var video_ready = false
        var player
        const open_modal = document.querySelector('.open-modal')
        const close_modal = document.querySelector('.close-modal')
        const modal = document.querySelector('.modal')
        const data_url = modal_video.dataset.url
        const data_type = modal_video.dataset.type

        
        const onYTPlayerReady = (event) => {
            video_ready = true
            event.target.playVideo();
        }
        
        const onVimeoPlayerReady = (event) => {
            video_ready = true
            player.play();
        }

        const stopVideo = () => {
            if( video_ready ) {
                if( data_type === "youtube" ){
                    player.stopVideo();
                }else if( data_type === "vimeo" ){
                    player.pause()
                    player.setCurrentTime(0)
                }
            }
        }
        
        /* ---------------------------------- CLOSE ---------------------------------- */

        close_modal.addEventListener('click', () => {
            stopVideo()
            modal.classList.add('d-none')
            modal.classList.remove('d-flex')
        })

        /* ---------------------------------- OPEN --------------------------------- */

        open_modal.addEventListener('click', () => {

            if( !video_ready ){
                
                
                if( data_type === "youtube" ){

                    const id = youtube_parser(data_url)
                    
                    player = new YT.Player('player', {
                        height: '100%',
                        width: '100%',
                        videoId: id,
                        events: {
                            'onReady': onYTPlayerReady,
                        }
                    })

                    
                }else if ( data_type === "vimeo" ){
                    
                    const id = vimeo_parser(data_url)
                    
                    player = new Vimeo.Player('player', {
                        id, width: '100%'
                    });
                    player.ready().then( onVimeoPlayerReady() );
                }

                
            }else{
                
                if( data_type === "youtube" ){
                    player.playVideo()
                }else if ( data_type === "vimeo" ){
                    player.play()
                }

            }
            
            modal.classList.remove('d-none')
            modal.classList.add('d-flex')

        })

    }

});
